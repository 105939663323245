import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type UserModel from './user.ts';
import type PublicAssetModel from './public-asset.ts';
import type AccountModel from './account.ts';
import type PostalAddressModel from './postal-address.ts';
import type AttachmentModel from './attachment.ts';
import type AccountTransactionModel from './account-transaction.ts';

export default class FinancialInstitutionModel extends Model {
  @attr declare name: string;
  @attr declare publicOrdinalValue: number;
  @attr declare isActive: boolean;
  @attr declare isPublic: boolean;
  @attr declare manifestInstitutionId: string;
  @attr declare provider?: 'FINICITY_CONNECT_LITE' | 'SPINWHEEL' | 'UNLINKED';
  @attr declare legacyId: string;
  @attr declare surrogateUserId: string;
  @attr declare fundSource: string;
  @attr declare canLinkOn: string;
  @attr declare statusMessage: string;
  @attr declare currency?: 'USD' | 'CAD';
  @attr declare hasObservabilityInstructions: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
  @belongsTo('public-asset', { async: false, inverse: null })
  declare logo: PublicAssetModel;
  // This is the wrong inverse, should be 'financialInstitution' but fixing
  // breaks linking so will return to it later.
  @hasMany('account', { async: false, inverse: null })
  declare accounts: AccountModel[];
  @hasMany('account-transaction', { async: false, inverse: null })
  declare accountTransactions: AccountTransactionModel[];
  @hasMany('postal-address', { async: false, inverse: null })
  declare postalAddresses: PostalAddressModel[];
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'financial-institution': FinancialInstitutionModel;
  }
}
